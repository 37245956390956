import {ssrGetListblog} from '~/graphql/operations/blog/get-list-blog-query.generated';
import * as Types from '../../graphql/types';

async function getListBlogByTag(
  locale?: string,
  tagName?: Array<string>,
  limit: Types.NewsCollection['limit'] = 10,
  calendarReel?: string,
  skip: Types.NewsCollection['skip'] = 0
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
) {
  let newQuery: any = {};
  type IQuery = {
    primaryTag?: string;
    keywords_contains_all?: string;
  };
  if (calendarReel) {
    newQuery.publishDate_gte = calendarReel;
  }

  if (tagName) {
    const arrayQuery: string[] = [];
    tagName.map(name => {
      const query: IQuery = {
        primaryTag: name,
      };
      const queryKeyword: IQuery = {
        keywords_contains_all: name,
      };
      arrayQuery.push(queryKeyword as any);
      arrayQuery.push(query as any);
    });
    newQuery = {
      OR: arrayQuery,
    };
  }
  newQuery.title_exists = true;
  const {
    props: {data},
  } = await ssrGetListblog.getServerPage(
    {
      variables: {
        limit,
        skip,
        where: newQuery,
        locale,
      },
    },
    {}
  );

  return data?.blogCollection;
}

export default getListBlogByTag;
