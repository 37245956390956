import {createSlice} from '@reduxjs/toolkit';
import {ListingType} from '@wearesection/bandai-react-components/molecules/FilterSelectionListing';
import {IProductItem} from '@wearesection/bandai-react-components/organisms/EStoreProduct/ProductItem';
import {TypeDataFilter} from '@wearesection/bandai-react-components/organisms/ScrollingFilterListing';

type IProductList = {
  dataFilter: TypeDataFilter[];
  filterPrice: ListingType[];
  sortBy: ListingType[];
  tabSelect: any;
  sortItem: string;
  filterItem: any;
  productData: IProductItem[];
  endCursor: any;
  endFeatured: boolean;
};

const initialState: IProductList = {
  dataFilter: [],
  sortBy: [],
  filterPrice: [],
  tabSelect: null,
  sortItem: 'FEATURED',
  filterItem: {},
  productData: [],
  endCursor: '',
  endFeatured: false,
};

export const productList = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setDataFilter: (state, action) => {
      state.dataFilter = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setFilterPrice: (state, action) => {
      state.filterPrice = action.payload;
    },
    setTabSelect: (state, action) => {
      state.tabSelect = action.payload;
    },
    setSortItem: (state, action) => {
      state.sortItem = action.payload;
    },
    setFilterItem: (state, action) => {
      state.filterItem = action.payload;
    },
    addProductData: (state, action) => {
      state.productData = [...state.productData, ...action.payload];
    },
    setProductData: (state, action) => {
      state.productData = action.payload;
    },
    setEndCursor: (state, action) => {
      state.endCursor = action.payload;
    },
    setEndFeatured: (state, action) => {
      state.endFeatured = action.payload;
    },
  },
});

export const {
  setFilterPrice,
  setSortBy,
  setDataFilter,
  setTabSelect,
  setSortItem,
  setFilterItem,
  addProductData,
  setProductData,
  setEndCursor,
  setEndFeatured,
} = productList.actions;

export default productList.reducer;
