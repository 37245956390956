import cookie from 'js-cookie';
import axiosClient from './axios-client';
const url = '/cart';

const cartApi = {
  createCard(data: any) {
    return axiosClient({
      method: 'POST',
      url,
      data,
    });
  },
  addItemToCard(data: any) {
    return axiosClient({
      method: 'POST',
      url: url + '/cart-items',
      data,
    });
  },
  getCart(random?: any) {
    const currencyCode = cookie.get('currentToken') || 'SGD';
    return axiosClient({
      method: 'GET',
      url: `${url}?&currencyCode=${currencyCode}&random=${random}`,
    });
  },
  deleteCart() {
    const currencyCode = cookie.get('currentToken') || 'SGD';
    return axiosClient({
      method: 'DELETE',
      url: url,
      data: {
        currencyCode,
      },
    });
  },
  deleteItemCart(data: any) {
    return axiosClient({
      method: 'DELETE',
      url: url + '/cart-items',
      data,
    });
  },
  updateItemCart(data: any) {
    const currencyCode = cookie.get('currentToken') || 'SGD';
    return axiosClient({
      method: 'PUT',
      url: url + '/cart-items',
      data: {
        ...data,
        currencyCode,
      },
    });
  },
  checkoutCart(token: string) {
    const currencyCode = cookie.get('currentToken') || 'SGD';
    return axiosClient({
      method: 'POST',
      url: '/checkout',
      data: {currencyCode: currencyCode},
      headers: {
        'x-access-token': token ?? '',
      },
    });
  },
  updateCustomerIdCart() {
    const currencyCode = cookie.get('currentToken') || 'SGD';
    return axiosClient({
      method: 'PUT',
      url,
      data: {
        currencyCode,
      },
    });
  },
  buyNow(data: any) {
    return axiosClient({
      method: 'POST',
      url: '/checkout-now',
      data,
    });
  },
};

export default cartApi;
