import React from 'react';
import {IScrollingThumbnailCard} from '@wearesection/bandai-react-components/organisms/ScrollingThumbnailCard';
import * as Types from 'graphql/types';
import {ComponentPropsAdapter} from '../../types';
import moment from 'moment';
import {GetRouter, handleCovertTagPrimary} from '~/utils';
import RenderNextImage from '../../atoms/render-next-image';
import {PLACEHOLDER_IMG} from '~/constants';
import {Box} from '@mui/material';
import dynamic from 'next/dynamic';
const ScrollingThumbnailCard = dynamic(
  () => import('@wearesection/bandai-react-components/organisms/ScrollingThumbnailCard'),
  {ssr: false}
);
type ThumbnailCardCarouselExt = Types.NewsListing & {};

const returnTagName = (locale?: string) => {
  const newLocale = locale?.toLowerCase();
  switch (newLocale) {
    case 'hk-tc':
    case 'tw-tc':
      return '遊戲指南';
    case 'hk-sc':
    case 'tw-sc':
      return '游戏指南';

    case 'ko-kr':
      return '팁 유용한 정보';
    default:
      return 'Tips and tricks';
  }
};

export const handleReturnDateForLocal = (local: string, item: any, onlyMonthYear?: boolean) => {
  const newLocal = local.toLowerCase();
  if (!item || item === 'null' || item === 'undefined') {
    return '';
  }
  switch (newLocal) {
    case 'hk-tc':
    case 'hk-sc':
    case 'tc':
      return onlyMonthYear
        ? item
          ? moment(item).locale('zh-hk').format('MMM Do')
          : ''
        : item
        ? moment(item).locale('zh-hk').format('ll')
        : '';
    case 'tw-tc':
    case 'tw-sc':
      return onlyMonthYear
        ? item
          ? moment(item).locale('zh-tw').format('MMM Do')
          : ''
        : item
        ? moment(item).locale('zh-tw').format('ll')
        : '';

    case 'ko-kr':
      return onlyMonthYear
        ? item
          ? moment(item).locale('ko').format('MMM Do')
          : ''
        : item
        ? moment(item).locale('ko').format('ll')
        : '';

    default:
      return onlyMonthYear
        ? item
          ? moment(item).locale('en-gb').format('D MMM')
          : ''
        : item
        ? moment(item).locale('en-gb').format('ll')
        : '';
  }
};

const adapter: ComponentPropsAdapter<ThumbnailCardCarouselExt, IScrollingThumbnailCard> = data => {
  const {pathname, locale} = GetRouter();
  const tagName = returnTagName(locale);
  return {
    isNewPage: pathname === '/news',
    title: data?.title ?? '',
    classSection: 'thumbnail-card-carousel',
    viewMore: {
      title: data?.seeAllText ?? '',
      href: `/${locale}${pathname}?tag=${tagName}`,
      target: data?.openNewTab ? '_blank' : '_parent',
    },
    listThumbnail:
      data?.listItemCollection?.items?.map(item => ({
        title: item?.title ?? '',
        linkHref: `/${locale}${pathname}/${item?.slug}` ?? '',
        mainImage: item?.mainImage?.media?.url ?? '',
        hrefTag: `/${locale}${pathname}`,
        type: 'news',
        mainImageNext: (
          <RenderNextImage
            src={item?.mainImage?.media?.url ?? PLACEHOLDER_IMG}
            width={357}
            height={307}
            alt={item?.title || ''}
          />
        ),
        tags: [
          {
            title: handleCovertTagPrimary(
              (item?.customTags as string)?.toUpperCase(),
              item?.game?.releaseDate ?? '',
              locale as string
            ),
            color: (data as any)?.listItemCollection?.listTags?.find(
              (e: any) =>
                e.name ===
                (
                  handleCovertTagPrimary(
                    (item?.customTags as string)?.toUpperCase(),
                    item?.game?.releaseDate ?? '',
                    locale as string
                  ) as string
                )?.toUpperCase()
            )?.backgroundColor,
          },
        ],
        fromDate: {
          month: item?.fromDate ? moment(item?.fromDate).format('MMM') : '',
          day: item?.fromDate ? moment(item?.fromDate).format('D') : '',
        },
        dateRange: false,
        textDateRange: 'From',
        hideDateLeft: true,
        noDate: item?.noDateText ? moment(item?.noDateText).format('D MMM Y') : '',
        publicDate: handleReturnDateForLocal(
          locale || 'en-sg',
          `${item?.publishDate}`.substring(0, 10)
        ),
      })) ?? [],
  };
};

const PADDING_COMPONENT: any = {
  '/news': {
    paddingTop: '0px',
    paddingBottom: {
      xs: '72px',
      lg: '40px',
    },
  },
};

const ScrollingThumbnailCardOrganisms: React.FC<ThumbnailCardCarouselExt> = (props: any) => {
  const ScrollingThumbnailCardOrganismsProps = adapter(props);
  const {pathname} = GetRouter();
  const notReverse = pathname === '/news';

  return (
    <Box sx={PADDING_COMPONENT[pathname] ?? {}}>
      <ScrollingThumbnailCard {...ScrollingThumbnailCardOrganismsProps} isReverse={!notReverse} />
    </Box>
  );
};

export default ScrollingThumbnailCardOrganisms;
