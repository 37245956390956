export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Entry: [
      'Author',
      'Blog',
      'BlogListing',
      'Brand',
      'Button',
      'Characters',
      'CollectionsForGame',
      'ContentTypeAsset',
      'Editions',
      'ExploreNewUniverse',
      'FilterBy',
      'FilterType',
      'Game',
      'GameBrand',
      'GameCharacterList',
      'GameForCollectors',
      'GameGroup',
      'GameKeyFeatures',
      'GamesListing',
      'Global',
      'HyperlinkComponent',
      'ImageAutoSize',
      'ImageInARow',
      'KeyFeature',
      'KeyFeatureMultipleImagesWithDescription',
      'Mashthead',
      'MediaGallery',
      'NavigationPopular',
      'News',
      'NewsGoogleForm',
      'NewsListing',
      'Page',
      'PlatformListing',
      'Question',
      'QuestionListing',
      'Quote',
      'SaleEvent',
      'Section',
      'SeoStandard',
      'SortBy',
      'SuggestionCard',
      'SupportFaq',
      'Tags',
      'Theme',
    ],
    GameKeyFeaturesKeyFeatureContainerItem: [
      'KeyFeature',
      'KeyFeatureMultipleImagesWithDescription',
    ],
    MashtheadContent: ['ContentTypeAsset', 'Game'],
    ResourceLink: [
      'BlogContentResourcesBlock',
      'BlogContentResourcesHyperlink',
      'BlogContentResourcesInline',
      'CharactersAdditionalDescriptionResourcesBlock',
      'CharactersAdditionalDescriptionResourcesHyperlink',
      'CharactersAdditionalDescriptionResourcesInline',
      'CharactersDescriptionResourcesBlock',
      'CharactersDescriptionResourcesHyperlink',
      'CharactersDescriptionResourcesInline',
      'CollectionsForGameDescriptionResourcesBlock',
      'CollectionsForGameDescriptionResourcesHyperlink',
      'CollectionsForGameDescriptionResourcesInline',
      'NewsContentResourcesBlock',
      'NewsContentResourcesHyperlink',
      'NewsContentResourcesInline',
      'NewsPasswordDescriptionResourcesBlock',
      'NewsPasswordDescriptionResourcesHyperlink',
      'NewsPasswordDescriptionResourcesInline',
      'QuestionContentResourcesBlock',
      'QuestionContentResourcesHyperlink',
      'QuestionContentResourcesInline',
      'QuoteContentResourcesBlock',
      'QuoteContentResourcesHyperlink',
      'QuoteContentResourcesInline',
    ],
    SectionBlocksItem: [
      'Blog',
      'BlogListing',
      'ExploreNewUniverse',
      'Game',
      'GameCharacterList',
      'GameForCollectors',
      'GameGroup',
      'GameKeyFeatures',
      'GamesListing',
      'Global',
      'Mashthead',
      'MediaGallery',
      'News',
      'NewsListing',
      'PlatformListing',
      'QuestionListing',
      'SaleEvent',
      'SuggestionCard',
      'SupportFaq',
    ],
    _Node: [],
  },
};
export default result;
