import {createSlice} from '@reduxjs/toolkit';

type ICart = {
  dataCart: object;
  cardLength: number;
  toastMessageExisting: boolean;
  listCart: object[] | null;
};

const initialState: ICart = {
  dataCart: {},
  cardLength: 0,
  toastMessageExisting: false,
  listCart: null,
};

export const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateToastMessageExisting: (state, action) => {
      state.toastMessageExisting = action.payload;
    },
    updateCartLength: (state, action) => {
      state.cardLength = action.payload;
    },
    updateListCart: (state, action) => {
      if (state.listCart !== null) {
        state.listCart[action.payload?.index] = action.payload?.data;
      }
    },
    updateDataCart: (state, action) => {
      state.dataCart = action.payload;
    },
    setEmptyCart: state => {
      state.listCart = [];
    },
    addListCart: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.listCart = action.payload;
      } else {
        state?.listCart && state?.listCart.push(action.payload);
      }
    },
    resetCartWhenLogout: state => {
      state.dataCart = {};
      state.cardLength = 0;
      state.listCart = [];
      state.toastMessageExisting = false;
    },
  },
});

export const {
  updateToastMessageExisting,
  updateCartLength,
  updateListCart,
  updateDataCart,
  setEmptyCart,
  addListCart,
  resetCartWhenLogout,
} = cart.actions;

export default cart.reducer;
