export const PLACEHOLDER_IMG =
  'https://images.ctfassets.net/hnoi7ctzfs57/1Flq5y1eK7lSIaYUx3FmQE/9e088801de98e35658e1d0dd1f423e60/placeholder.jpg';

export const IMG_NOT_FOUND =
  'https://images.ctfassets.net/hnoi7ctzfs57/6ITH9fmyysAbuvwk6W0QKP/ee0b0c4f9d80a148c4464cd65d180509/No_searching_result.svg';

export const IMG_NO_PATCH_NOTES =
  'https://images.ctfassets.net/hnoi7ctzfs57/6hdRjJmj8iJopVXWQgwNQw/05c510d7273f577b60511503c05fa0c9/No_patchnotes_2.png?h=250';

export const COUNTRY_LIST = [
  {label: 'Afghanistan', value: 'Afghanistan'},
  {label: 'Albania', value: 'Albania'},
  {label: 'Algeria', value: 'Algeria'},
  {label: 'American Samoa', value: 'American Samoa'},
  {label: 'Andorra', value: 'Andorra'},
  {label: 'Angola', value: 'Angola'},
  {label: 'Anguilla', value: 'Anguilla'},
  {label: 'Antarctica', value: 'Antarctica'},
  {label: 'Antigua and Barbuda', value: 'Antigua and Barbuda'},
  {label: 'Argentina', value: 'Argentina'},
  {label: 'Armenia', value: 'Armenia'},
  {label: 'Aruba', value: 'Aruba'},
  {label: 'Australia', value: 'Australia'},
  {label: 'Austria', value: 'Austria'},
  {label: 'Azerbaijan', value: 'Azerbaijan'},
  {label: 'Bahamas', value: 'Bahamas'},
  {label: 'Bahrain', value: 'Bahrain'},
  {label: 'Bangladesh', value: 'Bangladesh'},
  {label: 'Barbados', value: 'Barbados'},
  {label: 'Belarus', value: 'Belarus'},
  {label: 'Belgium', value: 'Belgium'},
  {label: 'Belize', value: 'Belize'},
  {label: 'Benin', value: 'Benin'},
  {label: 'Bermuda', value: 'Bermuda'},
  {label: 'Bhutan', value: 'Bhutan'},
  {label: 'Bolivia', value: 'Bolivia'},
  {label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina'},
  {label: 'Botswana', value: 'Botswana'},
  {label: 'Brazil', value: 'Brazil'},
  {label: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory'},
  {label: 'British Virgin Islands', value: 'British Virgin Islands'},
  {label: 'Brunei', value: 'Brunei'},
  {label: 'Bulgaria', value: 'Bulgaria'},
  {label: 'Burkina Faso', value: 'Burkina Faso'},
  {label: 'Burundi', value: 'Burundi'},
  {label: 'Cambodia', value: 'Cambodia'},
  {label: 'Cameroon', value: 'Cameroon'},
  {label: 'Canada', value: 'Canada'},
  {label: 'Cape Verde', value: 'Cape Verde'},
  {label: 'Cayman Islands', value: 'Cayman Islands'},
  {label: 'Central African Republic', value: 'Central African Republic'},
  {label: 'Chad', value: 'Chad'},
  {label: 'Chile', value: 'Chile'},
  {label: 'China', value: 'China'},
  {label: 'Colombia', value: 'Colombia'},
  {label: 'Comoros', value: 'Comoros'},
  {label: 'Cook Islands', value: 'Cook Islands'},
  {label: 'Costa Rica', value: 'Costa Rica'},
  {label: 'Croatia', value: 'Croatia'},
  {label: 'Cuba', value: 'Cuba'},
  {label: 'Curacao', value: 'Curacao'},
  {label: 'Cyprus', value: 'Cyprus'},
  {label: 'Czech Republic', value: 'Czech Republic'},
  {label: 'Democratic Republic of the Congo', value: 'Democratic Republic of the Congo'},
  {label: 'Denmark', value: 'Denmark'},
  {label: 'Djibouti', value: 'Djibouti'},
  {label: 'Dominica', value: 'Dominica'},
  {label: 'Dominica', value: 'Dominica'},
  {label: 'Dominica', value: 'Dominica'},
  {label: 'Dominican Republic', value: 'Dominican Republic'},
  {label: 'East Timor', value: 'East Timor'},
  {label: 'Ecuador', value: 'Ecuador'},
  {label: 'Egypt', value: 'Egypt'},
  {label: 'El Salvador', value: 'El Salvador'},
  {label: 'Equatorial Guinea', value: 'Equatorial Guinea'},
  {label: 'Eritrea', value: 'Eritrea'},
  {label: 'Estonia', value: 'Estonia'},
  {label: 'Ethiopia', value: 'Ethiopia'},
  {label: 'Falkland Islands', value: 'Falkland Islands'},
  {label: 'Faroe Islands', value: 'Faroe Islands'},
  {label: 'Fiji', value: 'Fiji'},
  {label: 'Finland', value: 'Finland'},
  {label: 'France', value: 'France'},
  {label: 'French Polynesia', value: 'French Polynesia'},
  {label: 'Gabon', value: 'Gabon'},
  {label: 'Gambia', value: 'Gambia'},
  {label: 'Georgia', value: 'Georgia'},
  {label: 'Germany', value: 'Germany'},
  {label: 'Ghana', value: 'Ghana'},
  {label: 'Gibraltar', value: 'Gibraltar'},
  {label: 'Greece', value: 'Greece'},
  {label: 'Greenland', value: 'Greenland'},
  {label: 'Grenada', value: 'Grenada'},
  {label: 'Guam', value: 'Guam'},
  {label: 'Guatemala', value: 'Guatemala'},
  {label: 'Guernsey', value: 'Guernsey'},
  {label: 'Guinea', value: 'Guinea'},
  {label: 'Guinea-Bissau', value: 'Guinea-Bissau'},
  {label: 'Guyana', value: 'Guyana'},
  {label: 'Haiti', value: 'Haiti'},
  {label: 'Honduras', value: 'Honduras'},
  {label: 'Hong Kong', value: 'Hong Kong'},
  {label: 'Hungary', value: 'Hungary'},
  {label: 'Iceland', value: 'Iceland'},
  {label: 'India', value: 'India'},
  {label: 'Indonesia', value: 'Indonesia'},
  {label: 'Iran', value: 'Iran'},
  {label: 'Iraq', value: 'Iraq'},
  {label: 'Ireland', value: 'Ireland'},
  {label: 'Isle of Man', value: 'Isle of Man'},
  {label: 'Israel', value: 'Israel'},
  {label: 'Italy', value: 'Italy'},
  {label: 'Ivory Coast', value: 'Ivory Coast'},
  {label: 'Jamaica', value: 'Jamaica'},
  {label: 'Japan', value: 'Japan'},
  {label: 'Jersey', value: 'Jersey'},
  {label: 'Jordan', value: 'Jordan'},
  {label: 'Kazakhstan', value: 'Kazakhstan'},
  {label: 'Kenya', value: 'Kenya'},
  {label: 'Kiribati', value: 'Kiribati'},
  {label: 'Kosovo', value: 'Kosovo'},
  {label: 'Kuwait', value: 'Kuwait'},
  {label: 'Kyrgyzstan', value: 'Kyrgyzstan'},
  {label: 'Laos', value: 'Laos'},
  {label: 'Latvia', value: 'Latvia'},
  {label: 'Lebanon', value: 'Lebanon'},
  {label: 'Lesotho', value: 'Lesotho'},
  {label: 'Liberia', value: 'Liberia'},
  {label: 'Libya', value: 'Libya'},
  {label: 'Liechtenstein', value: 'Liechtenstein'},
  {label: 'Lithuania', value: 'Lithuania'},
  {label: 'Luxembourg', value: 'Luxembourg'},
  {label: 'Macau', value: 'Macau'},
  {label: 'Macedonia', value: 'Macedonia'},
  {label: 'Madagascar', value: 'Madagascar'},
  {label: 'Malawi', value: 'Malawi'},
  {label: 'Malaysia', value: 'Malaysia'},
  {label: 'Maldives', value: 'Maldives'},
  {label: 'Mali', value: 'Mali'},
  {label: 'Malta', value: 'Malta'},
  {label: 'Marshall Islands', value: 'Marshall Islands'},
  {label: 'Mauritania', value: 'Mauritania'},
  {label: 'Mauritius', value: 'Mauritius'},
  {label: 'Mayotte', value: 'Mayotte'},
  {label: 'Mexico', value: 'Mexico'},
  {label: 'Micronesia', value: 'Micronesia'},
  {label: 'Moldova', value: 'Moldova'},
  {label: 'Monaco', value: 'Monaco'},
  {label: 'Mongolia', value: 'Mongolia'},
  {label: 'Montenegro', value: 'Montenegro'},
  {label: 'Montserrat', value: 'Montserrat'},
  {label: 'Morocco', value: 'Morocco'},
  {label: 'Mozambique', value: 'Mozambique'},
  {label: 'Myanmar', value: 'Myanmar'},
  {label: 'Namibia', value: 'Namibia'},
  {label: 'Nauru', value: 'Nauru'},
  {label: 'Nepal', value: 'Nepal'},
  {label: 'Netherlands', value: 'Netherlands'},
  {label: 'Netherlands Antilles', value: 'Netherlands Antilles'},
  {label: 'New Caledonia', value: 'New Caledonia'},
  {label: 'New Zealand', value: 'New Zealand'},
  {label: 'Nicaragua', value: 'Nicaragua'},
  {label: 'Niger', value: 'Niger'},
  {label: 'Nigeria', value: 'Nigeria'},
  {label: 'Niue', value: 'Niue'},
  {label: 'North Korea', value: 'North Korea'},
  {label: 'Northern Mariana Islands', value: 'Northern Mariana Islands'},
  {label: 'Norway', value: 'Norway'},
  {label: 'Oman', value: 'Oman'},
  {label: 'Pakistan', value: 'Pakistan'},
  {label: 'Palau', value: 'Palau'},
  {label: 'Palestine', value: 'Palestine'},
  {label: 'Panama', value: 'Panama'},
  {label: 'Papua New Guinea', value: 'Papua New Guinea'},
  {label: 'Paraguay', value: 'Paraguay'},
  {label: 'Peru', value: 'Peru'},
  {label: 'Philippines', value: 'Philippines'},
  {label: 'Pitcairn', value: 'Pitcairn'},
  {label: 'Poland', value: 'Poland'},
  {label: 'Portugal', value: 'Portugal'},
  {label: 'Puerto Rico', value: 'Puerto Rico'},
  {label: 'Puerto Rico', value: 'Puerto Rico'},
  {label: 'Qatar', value: 'Qatar'},
  {label: 'Republic of the Congo', value: 'Republic of the Congo'},
  {label: 'Reunion', value: 'Reunion'},
  {label: 'Romania', value: 'Romania'},
  {label: 'Russia', value: 'Russia'},
  {label: 'Rwanda', value: 'Rwanda'},
  {label: 'Saint Barthelemy', value: 'Saint Barthelemy'},
  {label: 'Saint Helena', value: 'Saint Helena'},
  {label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis'},
  {label: 'Saint Lucia', value: 'Saint Lucia'},
  {label: 'Saint Martin', value: 'Saint Martin'},
  {label: 'Saint Pierre and Miquelon', value: 'Saint Pierre and Miquelon'},
  {label: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines'},
  {label: 'Samoa', value: 'Samoa'},
  {label: 'San Marino', value: 'San Marino'},
  {label: 'Sao Tome and Principe', value: 'Sao Tome and Principe'},
  {label: 'Saudi Arabia', value: 'Saudi Arabia'},
  {label: 'Senegal', value: 'Senegal'},
  {label: 'Serbia', value: 'Serbia'},
  {label: 'Seychelles', value: 'Seychelles'},
  {label: 'Sierra Leone', value: 'Sierra Leone'},
  {label: 'Singapore', value: 'Singapore'},
  {label: 'Sint Maarten', value: 'Sint Maarten'},
  {label: 'Slovakia', value: 'Slovakia'},
  {label: 'Slovenia', value: 'Slovenia'},
  {label: 'Solomon Islands', value: 'Solomon Islands'},
  {label: 'Somalia', value: 'Somalia'},
  {label: 'South Africa', value: 'South Africa'},
  {label: 'South Korea', value: 'South Korea'},
  {label: 'South Sudan', value: 'South Sudan'},
  {label: 'Spain', value: 'Spain'},
  {label: 'Sri Lanka', value: 'Sri Lanka'},
  {label: 'Sudan', value: 'Sudan'},
  {label: 'Suriname', value: 'Suriname'},
  {label: 'Svalbard and Jan Mayen', value: 'Svalbard and Jan Mayen'},
  {label: 'Swaziland', value: 'Swaziland'},
  {label: 'Sweden', value: 'Sweden'},
  {label: 'Switzerland', value: 'Switzerland'},
  {label: 'Syria', value: 'Syria'},
  {label: 'Taiwan', value: 'Taiwan'},
  {label: 'Tajikistan', value: 'Tajikistan'},
  {label: 'Tanzania', value: 'Tanzania'},
  {label: 'Thailand', value: 'Thailand'},
  {label: 'Togo', value: 'Togo'},
  {label: 'Tokelau', value: 'Tokelau'},
  {label: 'Tonga', value: 'Tonga'},
  {label: 'Trinidad and Tobago', value: 'Trinidad and Tobago'},
  {label: 'Tunisia', value: 'Tunisia'},
  {label: 'Turkey', value: 'Turkey'},
  {label: 'Turkmenistan', value: 'Turkmenistan'},
  {label: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands'},
  {label: 'Tuvalu', value: 'Tuvalu'},
  {label: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands'},
  {label: 'Uganda', value: 'Uganda'},
  {label: 'Ukraine', value: 'Ukraine'},
  {label: 'United Arab Emirates', value: 'United Arab Emirates'},
  {label: 'United Kingdom', value: 'United Kingdom'},
  {label: 'United States', value: 'United States'},
  {label: 'Uruguay', value: 'Uruguay'},
  {label: 'Uzbekistan', value: 'Uzbekistan'},
  {label: 'Vanuatu', value: 'Vanuatu'},
  {label: 'Vatican', value: 'Vatican'},
  {label: 'Venezuela', value: 'Venezuela'},
  {label: 'Vietnam', value: 'Vietnam'},
  {label: 'Wallis and Futuna', value: 'Wallis and Futuna'},
  {label: 'Western Sahara', value: 'Western Sahara'},
  {label: 'Yemen', value: 'Yemen'},
  {label: 'Zambia', value: 'Zambia'},
  {label: 'Zimbabwe', value: 'Zimbabwe'},
];

export const GAME_PARAM_SORT: any = {
  'New to old': 'releaseDate_DESC',
  'Title (Z to A)': 'title_DESC',
  'Title (A to Z)': 'title_ASC',
};

export const DATA_PAGE_PATHS = ['', 'contact-us'];

export const MAPPING_LOCALES = [
  {
    value: 'en-SG',
    locales: ['en-SG'],
  },
  {
    value: 'ko-KR',
    locales: ['ko-KR'],
  },
  {
    value: 'ko-KR',
    locales: ['zh-Hans'],
  },
  {
    value: 'ko-KR',
    locales: ['zh-Hant'],
  },
];

export const MAPPING_LOCALES_COUNTRY = [
  {
    country: ['kr'],
    locales: ['ko-kr'],
    name: 'Korea',
  },
  {
    country: ['vn', 'th', 'sg', 'ph', 'mm', 'my', 'la', 'id', 'tl', 'kh', 'bn'],
    locales: ['sea'],
    name: 'Southeast Asia (SEA)',
  },
  {
    country: ['jp'],
    locales: ['ja'],
    name: 'Japan',
  },
  {
    country: ['us'],
    locales: ['en-us'],
    name: 'America',
  },
  {
    country: ['hk'],
    locales: ['hk-sc', 'hk-tc'],
    name: 'Hong Kong',
  },
  {
    country: ['tw'],
    locales: ['tw-sc', 'tw-tc'],
    name: 'Taiwan',
  },
  {
    country: [
      'fr',
      'ua',
      'es',
      'se',
      'de',
      'fi',
      'no',
      'pl',
      'it',
      'gb',
      'ro',
      'by',
      'ru',
      'gr',
      'bg',
      'is',
      'hu',
      'pt',
      'rs',
      'at',
      'cz',
      'ie',
      'lt',
      'lv',
      'sj',
      'hr',
      'ba',
      'sk',
      'ee',
      'dk',
      'ch',
      'nl',
      'md',
      'be',
      'al',
      'mk',
      'si',
      'me',
      'ko',
      'lu',
      'fo',
      'ad',
      'mt',
      'li',
      'je',
      'gg',
      'sm',
      'gl',
      'mc',
      'va',
    ],
    locales: ['eu'],
    name: 'Europe',
  },
];

export const TABS_FILTER_BLOG = [
  'Games',
  'News',
  'Events',
  '게임',
  '최신 정보',
  '最新游戏',
  '最新遊戲',
  '이벤트',
  '活动情报',
  '活動情報',
  '게임 정보',
  '游戏信息',
  '遊戲資訊',
];

export const LIST_ID_GAME_OUR_PICK: any = {
  'en-SG': '2AlUI8OA3MuZmiqrIsKYtQ',
  'zh-Hant': '7bRAgevQxQ3HsXmMbBhnmq',
  'zh-Hans': '3STCWsdzUTrqECi4wq0stc',
  'ko-KR': '76ajBZ2XzEiRJpH2zBnXzk',
};

export const LIST_ID_GAME_POPULAR: any = {
  'en-SG': 'B9J0QYQI7E0OCDByU8jAH',
  'zh-Hant': '6ljl8Pc9Jpr9SmvIKcyBsJ',
  'zh-Hans': '5c2haDYiTWQopUXBviPl2I',
  'ko-KR': '1vqDgcsmvc3GceRgyQmjQE',
};
