import {createSlice} from '@reduxjs/toolkit';
import LocalStorage, {TITLE_LOCAL_STORAGE} from '~/local-storage/local-storage';

const productListRecentlyID = LocalStorage.getItem(TITLE_LOCAL_STORAGE.productListRecentlyID);

type IProductListRecently = {
  productListRecentlyID: any[];
};

const initialState: IProductListRecently = {
  productListRecentlyID: productListRecentlyID,
};

const handleCheckDuplicateRecentlyID = (listRecent: any[]) => {
  if (!listRecent?.length) return [];
  const obj: any = {};
  const result: any[] = [];
  listRecent.map(item => {
    if (!obj[item]) {
      obj[item] = item;
      result.push(item);
    }
  });
  return result;
};

export const productListRecentlySlice = createSlice({
  name: 'productListRecently',
  initialState,
  reducers: {
    setProductListRecentlyID: (state, action) => {
      const newListRecentlyID = handleCheckDuplicateRecentlyID([
        ...action.payload,
        ...state.productListRecentlyID,
      ]);
      state.productListRecentlyID = newListRecentlyID
        .filter((id: any) => {
          if (id) {
            return id * 1;
          }
        })
        ?.splice(0, 10);
      LocalStorage.setItem(TITLE_LOCAL_STORAGE.productListRecentlyID, state.productListRecentlyID);
    },
  },
});

export const {setProductListRecentlyID} = productListRecentlySlice.actions;

export default productListRecentlySlice.reducer;
