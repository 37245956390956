import {createSlice} from '@reduxjs/toolkit';
import LocalStorage, {TITLE_LOCAL_STORAGE} from '~/local-storage/local-storage';

const dataUser: any = LocalStorage.getItem(TITLE_LOCAL_STORAGE.dataUser);

type IUser = {
  dataUser: any;
};

const initialState: IUser = {
  dataUser: Array.isArray(dataUser) ? {} : dataUser,
};

const handelFindIndex = (id: any, address: any[]): number => {
  if (!address?.length) {
    return -1;
  }
  const index = address.findIndex(item => item.id === id);
  return index;
};

export const user = createSlice({
  name: 'users',
  initialState,
  reducers: {
    saveDataUser: (state, action) => {
      state.dataUser = action.payload;
      LocalStorage.setItem(TITLE_LOCAL_STORAGE.dataUser, state.dataUser);
    },
    removeDataUser: state => {
      state.dataUser = {};
    },
    removeAddress: (state, action) => {
      const id = action.payload;
      const index: number = handelFindIndex(id, state?.dataUser?.addresses?.data || []);
      state?.dataUser?.addresses?.data.splice(index, 1);

      state.dataUser = {
        ...state.dataUser,
      };

      LocalStorage.setItem(TITLE_LOCAL_STORAGE.dataUser, state.dataUser);
    },
    updateAddress: (state, action) => {
      const address = action.payload;
      const id = address?.id ?? '';
      const index: number = handelFindIndex(id, state?.dataUser?.addresses?.data || []);
      state?.dataUser?.addresses?.data.splice(index, 1, address);
      state.dataUser = {
        ...state.dataUser,
      };
      LocalStorage.setItem(TITLE_LOCAL_STORAGE.dataUser, state.dataUser);
    },
    addAddress: (state, action) => {
      const address = action.payload;
      state?.dataUser?.addresses?.data.push(address);
      state.dataUser = {
        ...state.dataUser,
      };
      LocalStorage.setItem(TITLE_LOCAL_STORAGE.dataUser, state.dataUser);
    },
  },
});

export const {saveDataUser, removeDataUser, removeAddress, updateAddress, addAddress} =
  user.actions;

export default user.reducer;
