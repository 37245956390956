export const TITLE_LOCAL_FOR_USER = {
  orderList: 'orderList',
  dataUser: 'dataUser',
};
export const TITLE_LOCAL_STORAGE = {
  navigation: 'navigation',
  dataGlobal: 'dataGlobal',
  ...TITLE_LOCAL_FOR_USER,
  productListRecentlyID: 'productListRecentlyID',
};

const LocalStorage = {
  getItem(title: string) {
    if (typeof window === 'undefined') return {};
    return JSON.parse(localStorage.getItem(title) || '[]');
  },
  setItem(title: string, value: any) {
    if (typeof window === 'undefined') return;
    localStorage.setItem(title, JSON.stringify(value));
  },
  removeItem(title: string) {
    if (typeof window === 'undefined') return;
    localStorage.removeItem(title);
  },
};

export default LocalStorage;
