import Image from 'next/image';
import React from 'react';
import {PLACEHOLDER_IMG} from '~/constants';

interface IRenderNextImageProps {
  src: string;
  alt?: string;
  width?: string | number;
  height?: string | number;
  layout?: any;
}

const RenderNextImage: React.FC<IRenderNextImageProps> = ({
  src,
  width = '100%',
  height = '100%',
  alt = '',
  layout,
}) => {
  return (
    <Image
      layout={layout}
      src={src || PLACEHOLDER_IMG}
      width={width}
      height={height}
      alt={alt || ''}
    />
  );
};

export default RenderNextImage;
