import {ssrGetListGames} from '~/graphql/operations/game/games-query.generated';
import {GAME_PARAM_SORT} from '~/constants';

async function getListGame(locale?: string, query = '', sort = 'New to old') {
  const newQuery: any = { AND: [], OR: [{ platform_contains_all: query }, { genre_contains_all: query }] };
  newQuery.title_exists = true;
  newQuery['AND'].push({"languagesPage_not_in": ["Thai","Indo"]})
  const {
    props: {data},
  } = await ssrGetListGames.getServerPage(
    {
      variables: {
        locale,
        skip: 0,
        limit: 3,
        where: newQuery,
        order: GAME_PARAM_SORT[sort],
      },
    },
    {}
  );

  return data?.gameCollection;
}

export async function getListGameByQueryValue(local: string, page: any) {
  const queryValue = page?.blocksCollection?.items?.[0]?.queryValue || page || [];
  const result: any[] = [];
  if (!queryValue.length) {
    return result;
  }
  const length = queryValue.length;
  for (let i = 0; i < length; i += 1) {
    const type = queryValue[i];
    const gamesList = await getListGame(local, type);
    const itemGame = gamesList?.items ?? [];
    if (itemGame?.length) {
      result.push(
        ...itemGame?.map((item: any) => {
          return {
            ...item,
            title: `${item?.title}`,
          };
        })
      );
    } else {
      result.push(...itemGame);
    }
  }

  return result;
}
