import {NextPage, GetServerSidePropsContext} from 'next';

import {
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from
} from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import {
  NextApiRequestCookies,
  // @ts-ignore This path is generated at build time and conflicts otherwise
} from 'next-server/server/api-utils';
import {IncomingMessage} from 'http';
import {isPreviewEnabled} from 'lib/preview';
import generatedIntrospection from 'graphql/possible-types.generated';
import ApolloLinkTimeout from "apollo-link-timeout";

export type ApolloClientContext = {
  req?: IncomingMessage & {
    cookies: NextApiRequestCookies;
  };
  ssrContext?: GetServerSidePropsContext;
};


export const withApollo = (Comp: NextPage) => (props: any) => {
  return (
    <ApolloProvider client={getApolloClient(undefined, props.apolloState)}>
      <Comp />
    </ApolloProvider>
  );
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const getApolloClient = (
  ctx?: ApolloClientContext,
  initialState?: NormalizedCacheObject
) => {
  const SPACE_ID = process.env.NEXT_PUBLIC_CF_SPACE_ID;
  const ACCESS_TOKEN = process.env.NEXT_PUBLIC_CF_DELIVERY_ACCESS_TOKEN;
  const PREVIEW_ACCESS_TOKEN = process.env.NEXT_PUBLIC_CF_PREVIEW_ACCESS_TOKEN;
  const NEXT_PUBLIC_CONTENTFUL_ENV = process.env.NEXT_PUBLIC_CONTENTFUL_ENV;


  const preview = !!(process && process.env && process.env.NEXT_PUBLIC_PREVIEW);
  
  const httpLink = createHttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${SPACE_ID}/environments/${NEXT_PUBLIC_CONTENTFUL_ENV}`,
    fetch,
    headers: {
      Authorization: `Bearer ${preview ? PREVIEW_ACCESS_TOKEN : ACCESS_TOKEN}`,
    },
  });

const TIMEOUT = 600000;
const timeoutLink = new ApolloLinkTimeout(TIMEOUT);

  const cache = new InMemoryCache({ possibleTypes: generatedIntrospection.possibleTypes }).restore(initialState || {});
  return new ApolloClient({
    link: from([errorLink, timeoutLink, httpLink]),
    cache,
    ssrMode: !process.browser,
  });
};
