/* eslint-disable @typescript-eslint/no-explicit-any */
import {isArray} from 'lodash';
import * as Types from 'graphql/types';
import {
  IconNitendo,
  IconPlaystation,
  IconXbox,
  IconSteam,
} from '@wearesection/bandai-react-components/atoms/Icon';
import {ITagsProps} from '@wearesection/bandai-react-components/atoms/Tags';
import {useRouter} from 'next/router';
import {PLACEHOLDER_IMG} from '~/constants';
import {getCookie} from 'cookies-next';
import LocalStorage, {TITLE_LOCAL_STORAGE} from '~/local-storage/local-storage';

export const FOR_STORE: any[] = [
  '/store',
  '/account',
  '/store/games',
  '/store/games/[slug]',
  '/store/merchandise',
  '/store/merchandise/[slug]',
  '/store/deal',
  '/login',
  '/logout',
  '/cart',
  '/forgot-password',
  '/register',
  '/change-password',
  '/store/search',
  '/login-passwordless',
  '/store/order-detail',
];

/**
 * Use to convert platform string to Icon
 * @param title
 * @returns Element
 */
export const convertPlatformToIcon = (title: Types.Maybe<string>) => {
  const icons: any = {
    Ni: IconNitendo,
    PS: IconPlaystation,
    Pl: IconPlaystation,
    XB: IconXbox,
    PC: IconSteam,
    St: IconSteam,
  };

  return icons[title || ''];
};

const uniqArray = (arr = []) => arr.reduce((t, v) => (t.includes(v) ? t : [...t, v]), []);

/**
 * Use to slice tags and remove string duplicate
 * @param platform
 * @returns Array<string>
 */
export const handleFilterTags = (platform: ITagsProps[]) => {
  const data: any = [];
  if (!Array.isArray(platform)) {
    return [];
  }
  const result = platform?.map(item => (item as any)?.slice(0, 2));

  const resultFilter = result?.filter(
    (item: string, index: number) => result?.indexOf(item) === index
  );

  const index = (resultFilter ?? [])?.indexOf('Pl');
  if (index !== -1) {
    resultFilter[index] = 'PS';
  }

  const arrayUniq = uniqArray((resultFilter as any) ?? []);

  arrayUniq.map(item => {
    if (item === 'PS') {
      data[0] = item;
    } else if (item === 'Ni') {
      data[1] = item;
    } else if (item === 'XB') {
      data[2] = item;
    } else if (item === 'PC') {
      data[3] = item;
    } else {
      data.push(item);
    }
  });

  const filtered = (data ?? [])?.filter((el: string) => {
    return el !== null;
  });

  return filtered;
};

// Convert string to Slug
export const convertToSlug = (string: string) => {
  return string
    .toLowerCase()
    .replace(/\+/g, '__')
    .replace(/-/g, '_')
    .replace(/ /g, '-')
    .replace(/\/\//g, '=>')
    .toLocaleLowerCase();
};

export const convertSlugToString = (string: string) => {
  if (typeof window !== 'undefined') {
    if (string) {
      const newSlug = string.split('?');
      if (newSlug.length) {
        const result = newSlug[0]
          ?.toLowerCase()
          .replace(/__/g, '+')
          .replace(/-/g, ' ')
          .replace(/_/g, '-')
          .replace(/=>/g, '//');
        return result;
      }
    }
    return string;
  }
  return string;
};

export const handleConvertToLocaleContentful = (locale: string) => {
  switch (`${locale}`.toLowerCase()) {
    case 'ko-kr':
      return 'ko-KR';
    case 'tw-tc':
      return 'zh-Hant';
    case 'tw-sc':
      return 'zh-Hans';
    case 'hk-tc':
      return 'zh-Hant';
    case 'hk-sc':
      return 'zh-Hans';
    case 'tc':
      return 'zh-Hant';
    default:
      return 'en-SG';
  }
};

/**
 * function handle return tag and color
 */

export const handleReturnColorTag = (
  nameTag: string,
  listTag: any,
  item?: any,
  locale?: string
) => {
  const result = {
    title: nameTag,
    color: '#00B0B8',
  };

  if (!item && !locale) {
    const itemColor =
      !!listTag.length &&
      listTag.find((tag: any) => tag.name?.toUpperCase().includes(nameTag?.toUpperCase()));
    if (itemColor?.name) {
      result.title = nameTag?.toUpperCase();
      result.color = itemColor.backgroundColor;
    }
  } else {
    const itemColor =
      !!listTag.length &&
      listTag.find((tag: any) =>
        tag.name
          ?.toUpperCase()
          ?.includes(
            handleCovertTagPrimary(
              (nameTag?.toUpperCase() as string)?.toUpperCase(),
              item ?? '',
              locale as string
            )?.toUpperCase()
          )
      );

    if (itemColor?.name) {
      result.title = handleCovertTagPrimary(
        (nameTag?.toUpperCase() as string)?.toUpperCase(),
        item ?? '',
        locale as string
      );
      result.color = itemColor.backgroundColor;
    }
  }

  return result;
};

export const handleReturnSubtitleLanguage = (
  subTitle: any[],
  locale?: string,
  isShow?: boolean
) => {
  let result = '';
  const localeLowerCase: string = locale?.toLowerCase() || '';
  if (!subTitle?.length && !isShow) {
    return result;
  }
  const Language: any = {
    english: 'EN',
    thailand: 'TH',
    thai: 'TH',
    'bahasa indonesia': 'BI',
    korean: 'KR',
    'traditional chinese': 'TC',
    'simplified chinese': 'SC',
    japanese: 'JP',
    en: 'EN',
    tc: 'TC',
    sc: 'SC',
    jp: 'JP',
    th: 'TH',
    kr: 'KO',
  };

  const LANGUAGE_KO: any = {
    english: '영어',
    thailand: '태국어',
    thai: '태국어',
    'bahasa indonesia': 'BI',
    korean: '한국어',
    'traditional chinese': '중국어(번제차)',
    'simplified chinese': '중국어(간제차)',
    japanese: '일본어',
    en: '영어',
    kr: '한국어',
    jp: '일본어',
    tc: '중국어(번제차)',
    sc: '중국어(간제차)',
    th: '태국어',
  };

  const LANGUAGE_SC: any = {
    english: '英文',
    thailand: '泰文',
    thai: '泰文',
    'bahasa indonesia': '印尼语',
    korean: '韩文',
    'traditional chinese': '繁体中文',
    'simplified chinese': '简体中文',
    japanese: '日文',
    en: '英文',
    tc: '繁体中文',
    sc: '简体中文',
    jp: '日文',
    th: '泰文',
    kr: '韩文',
  };

  const LANGUAGE_TC: any = {
    english: '英文',
    thailand: '泰文',
    thai: '泰文',
    'bahasa indonesia': '印尼語',
    korean: '韓文',
    'traditional chinese': '繁體中文',
    'simplified chinese': '簡體中文',
    japanese: '日文',
    en: '英文',
    tc: '繁體中文',
    sc: '簡體中文',
    jp: '日文',
    th: '泰文',
    kr: '韓文',
  };

  if (localeLowerCase.includes('ko')) {
    subTitle.map(item => {
      result += `${LANGUAGE_KO[`${item}`.trim().toLowerCase()] || item} - `;
      return null;
    });
    return result.slice(0, result.length - 2) || '';
  }

  if (localeLowerCase.includes('tc')) {
    subTitle.map(item => {
      result += `${LANGUAGE_TC[`${item}`.trim().toLowerCase()] || item} - `;
      return null;
    });
    return result.slice(0, result.length - 2) || '';
  }
  if (localeLowerCase.includes('sc')) {
    subTitle.map(item => {
      result += `${LANGUAGE_SC[`${item}`.trim().toLowerCase()] || item} - `;
      return null;
    });
    return result.slice(0, result.length - 2) || '';
  }

  subTitle.map(item => {
    result += `${Language[`${item}`.trim().toLowerCase()] || item} - `;
    return null;
  });

  return result.slice(0, result.length - 2) || '';
};

export const handleTranslateNews = (news: any, translation: any, item?: any, locale?: any) => {
  if (Array.isArray(news)) {
    return news.map((item: Types.Maybe<Types.News>) => {
      return {
        ...item,
        customTags:
          translation['news-tag-' + item?.customTags?.toLocaleLowerCase().replace(' ', '-')] ??
          null,
      };
    });
  }
  if (Array.isArray(news) && !news.length) {
    return news;
  } else {
    // return translation['news-tag-' + news?.toLocaleLowerCase().replace(' ', '-')]?.toUpperCase();
    return (
      handleCovertTagPrimary(
        translation['news-tag-' + news?.toLocaleLowerCase().replace(' ', '-')]?.toUpperCase(),
        item?.game?.releaseDate ?? '',
        locale as string
      ) ?? ''
    );
  }
};

export const handleTranslateBlogTag = (tag: string, translation: any) => {
  return translation['blog-tag-' + tag?.toLocaleLowerCase().replace(' ', '-')] ?? null;
};

export const handleTranslateGameGenres = (text: string[], translation: any) => {
  return text?.map((item: string) => {
    return translation[
      'game-genre-' +
        item
          ?.toLocaleLowerCase()
          .replace(/ /g, '-')
          .replace(/\//, '-')
          .replace(/\(/g, '')
          .replace(/\)/g, '')
    ];
  });
};

export const handleTranslateGameLanauges = (
  text: string[],
  translation: any,
  addSpace?: boolean
) => {
  return text?.map((item: string, idx: number) => {
    const language = translation['game-language-' + item?.toLocaleLowerCase().replace(/ /g, '-')];
    if (addSpace) {
      return `${language} ${idx < text.length - 1 && language ? '-' : ''} `;
    }
    return language;
  });
};

export const dateArithmetic = (date: Date, months: number): Date => {
  date.setMonth(date.getMonth() + months);
  return date;
};

export const handleReturnDateAfterSubtraction = (date: Date, months: number): Date => {
  date.setMonth(date.getMonth() - months);
  return date;
};

// Handle return tag on get data game filter or more game
export const handelReturnTag = (platform: (string | null | undefined)[] | null | undefined) => {
  const result: any = [];
  if (!platform) {
    return result;
  }
  if (platform?.length) {
    handleFilterTags(platform as []).map((item: any) => {
      result.push(item);
    });
  }
  return result;
};

export function GetRouter() {
  const router = useRouter();
  return router;
}
export const titleLowerCase = (str: string) => {
  const splitStr = str?.toLocaleLowerCase().split('');
  const firstCharacterUpperCase = splitStr[0].toLocaleUpperCase();
  splitStr.splice(0, 1, firstCharacterUpperCase);
  return splitStr.join('');
};

export const handleReturnTypeVideo = (id: string) => {
  if (id?.includes('youtu')) {
    return 'youtube';
  }
  if (id?.includes('facebook')) {
    return 'fb';
  }
  if (id?.includes('bilibili')) {
    return 'bilibili';
  }
  if (id?.includes('vimeo')) {
    return 'vimeo';
  }
  if (id?.length === 11) {
    return 'youtube';
  }
  if (id?.length === 12) {
    return 'bilibili';
  }
  if (id?.length === 8 || id?.length === 9) {
    return 'vimeo';
  }
  return 'youtube';
};

export const handleReturnVideoDirectly = (id: string) => {
  if (id?.includes('videos')) {
    return true;
  }
  return false;
};

export const handleReturnIDVideo = (id: string) => {
  if (id?.includes('youtu.be')) {
    return id?.split('/')[3];
  }
  if (id?.includes('bilibili')) {
    return id?.split('/')[4];
  }
  if (id?.includes('vimeo')) {
    return id?.split('/')[3];
  }
  return id;
};

export const handleReturnImg = (id: string, item: any) => {
  if (id?.includes('facebook')) {
    return (
      item?.media?.url ||
      'https://images.ctfassets.net/hnoi7ctzfs57/1Z82EdOFJAwgTKfYS7xXn7/0622400ffee7fca1f3bcc2bc4532ccbb/back_ground_video_fb.webp?h=550'
    );
  }
  if (id?.includes('youtu')) {
    return (
      `https://img.youtube.com/vi/${id.split('/')[3]}/0.jpg` ??
      `https://i.ytimg.com/vi/${id.split('/')[3]}/maxresdefault.jpg`
    );
  }

  if (id?.includes('vimeo')) {
    return `https://vumbnail.com/${id.split('/')[3]}.jpg`;
  }

  if (id?.length === 11) {
    return (
      `https://img.youtube.com/vi/${id}/0.jpg` ?? `https://i.ytimg.com/vi/${id}/maxresdefault.jpg`
    );
  }
  if (id?.length === 8 || id?.length === 9) {
    return `https://vumbnail.com/${id}.jpg`;
  }

  return item?.media?.url;
};

export const handleCovertTagPrimary = (tag: string, releaseDate: string, locale: string) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (
    // tag === 'GAME UPDATE' ||
    // tag === '게임 업데이트' ||
    // tag === '游戏更新' ||
    // tag === '遊戲更新' ||
    // tag === 'NEW GAME' ||
    // tag === '신작 게임' ||
    // tag === '最新游戏' ||
    // tag === '最新遊戲' ||
    tag === 'UPCOMING GAME' ||
    tag === '即将上市' ||
    tag === '即將發售' ||
    tag === '발매 예정' ||
    tag === '발매 예정 게임'
  ) {
    if (!releaseDate) {
      return handleReturnTextUpComing(locale?.toLocaleLowerCase());
    } else {
      const varDate = new Date(releaseDate); //dd-mm-YYYY

      if (varDate > today) {
        return handleReturnTextUpComing(locale?.toLocaleLowerCase());
      }
      if (today.toString() === varDate.toString()) {
        return handleReturnNewGame(locale?.toLocaleLowerCase());
      }

      // Check within 3 moths
      let months =
        varDate.getMonth() - today.getMonth() + 12 * (varDate.getFullYear() - today.getFullYear());

      if (varDate.getDate() < today.getDate()) {
        months--;
      }
      if (-months <= 3) {
        return handleReturnNewGame(locale?.toLocaleLowerCase());
      }
      if (-months > 3) {
        return handleReturnGameUpdate(locale?.toLocaleLowerCase());
      }
    }
  }
  return tag;
};

const handleReturnTextUpComing = (locale: string) => {
  if (locale === 'tw-sc' || locale === 'hk-sc') {
    return '即将上市';
  } else if (locale === 'tw-tc' || locale === 'hk-tc') {
    return '即將發售';
  } else if (locale === 'ko-kr') {
    return '발매 예정 게임';
  }
  return 'UPCOMING GAME';
};

const handleReturnNewGame = (locale: string) => {
  if (locale === 'tw-sc' || locale === 'hk-sc') {
    return '最新游戏';
  } else if (locale === 'tw-tc' || locale === 'hk-tc') {
    return '最新遊戲';
  } else if (locale === 'ko-kr') {
    return '신작 게임';
  }
  return 'NEW GAME';
};

const handleReturnGameUpdate = (locale: string) => {
  if (locale === 'tw-sc' || locale === 'hk-sc') {
    return '游戏更新';
  } else if (locale === 'tw-tc' || locale === 'hk-tc') {
    return '遊戲更新';
  } else if (locale === 'ko-kr') {
    return '게임 업데이트';
  }
  return 'GAME UPDATE';
};

export const handleRemoveDuplicateItem = (arr: any, key: string) => {
  const uniqueIds: any[] = [];
  const unique = arr.filter((element: any) => {
    const isDuplicate = uniqueIds.includes(element[key]);

    if (!isDuplicate) {
      uniqueIds.push(element[key]);

      return true;
    }

    return false;
  });
  return unique;
};

export const handleRenderPrice = (price: string, current?: any, locale?: any) => {
  const newLocale = `${locale}`.toLowerCase();
  const currentValue = JSON.parse((getCookie('currentValue') as string) || '{}');
  if (!price) {
    return price;
  }

  const arrSlip = `${price}`?.split('.');
  const priceDecimal = `${arrSlip[1] || '00'}00`.slice(0, 2);
  if (newLocale === 'tc') {
    return `${
      current === 'TWD' ? 'NDT' : current || currentValue?.symbol || 'S$'
    }${`${arrSlip[0]}`.replace(/(\d)(?=(\d{3})+\b)/g, '$1,')}`;
  }
  if (newLocale === 'ko-kr') {
    return `${`${arrSlip[0]}`.replace(/(\d)(?=(\d{3})+\b)/g, '$1,')}${
      current || currentValue?.symbol || '원'
    }`;
  }
  return `${current || currentValue?.symbol || 'S$'}${`${arrSlip[0]}`.replace(
    /(\d)(?=(\d{3})+\b)/g,
    '$1,'
  )}.${priceDecimal}`;
};

export const handleRenderPriceHomePageMashThead = (price: string, locale?: string) => {
  if (!price) {
    return price;
  }
  const newLocale = `${locale}`.toLowerCase();
  const arrSlip = `${price}`?.split('.');
  const priceDecimal = `${arrSlip[1] || '00'}00`.slice(0, 2);
  return ['ko-kr', 'hk-tc', 'tw-sc', 'hk-sc', 'tw-tc', 'tc'].includes(newLocale)
    ? `${`${arrSlip[0]}`.replace(/(\d)(?=(\d{3})+\b)/g, '$1,')}`
    : `${`${arrSlip[0]}`.replace(/(\d)(?=(\d{3})+\b)/g, '$1,')}.${priceDecimal}`;
};

export const handleReturnCurrency = (locale?: string) => {
  if (!locale) {
    return '';
  }
  const newLocale = `${locale}`.toLowerCase();
  if (['tw-tc', 'tw-sc'].includes(newLocale)) {
    return 'NTD';
  }
  if (['hk-tc', 'hk-sc'].includes(newLocale)) {
    return 'HKD';
  }
  if (['ko-kr'].includes(newLocale)) {
    return '원';
  }
  return 'S$';
};

export const handelConvertSimple = (currency: string): string => {
  if (!currency || currency === 'SGD') {
    return 'S$';
  }

  let result = currency;
  const navigation = LocalStorage.getItem(TITLE_LOCAL_STORAGE.navigation);
  if (isArray(navigation)) {
    return '';
  }

  const listCountryShipping = navigation?.contentModalChangeShipping?.listCountryShipping || [];
  if (listCountryShipping?.length) {
    const itemCurrent = listCountryShipping.find((item: any) => item?.currency === currency);
    if (itemCurrent) {
      result = itemCurrent?.symbol || 'S$';
    }
  }
  return result;
};
// Handle convert cart data
export const handSaveCartDataToStore = (response: any) => {
  const data =
    response?.line_items?.physical_items?.map((item: any) => {
      return {
        id: item?.id,
        isMerchandise: !!item?.isMerchandise,
        title: item?.name ?? '',
        mainImage: item?.image_url ?? PLACEHOLDER_IMG,
        linkHref: '/',
        edition: item?.modifiers?.label ?? '',
        originalPrice: item?.original_price,
        promoPrice: item?.sale_price,
        productId: item?.product_id,
        quantity: item?.quantity ?? 1,
        inventory_level: item?.inventory_level,
        variants: item.variants,
        variants_id: item.variant_id,
        idCart: response?.id || '',
      };
    }) || [];
  return data;
};

export const highlight = (text: string, title: string) => {
  let innerHTML = title;

  const textTrim = text?.toLocaleLowerCase()?.trim();

  const index = innerHTML.toLocaleLowerCase().indexOf(textTrim);
  if (index >= 0) {
    innerHTML =
      innerHTML.substring(0, index) +
      "<span class='highlight'>" +
      innerHTML.substring(index, index + textTrim.length) +
      '</span>' +
      innerHTML.substring(index + textTrim.length);
    return innerHTML;
  }
};

// Detect locale
export const handleCovertLocale = (locale: string) => {
  if (`${locale}`.includes('us') || `${locale}`.includes('eu') || `${locale}`.includes('sea')) {
    return 'en';
  }
  if (`${locale}`.includes('tc')) {
    return 'tc';
  }
  if (`${locale}`.includes('sc')) {
    return 'sc';
  }
  if (`${locale}`.includes('ko')) {
    return 'ko';
  }
  return 'en';
};

export const handelRenderConfigDigitalRiverCheckout = (currencyCode = 'HKD') => {
  const text =
    '<p>你所訂購的商品將送往<b>' +
    (currencyCode === 'TWD' ? '台灣' : '香港') +
    '</b>，訂單金額將以' +
    (currencyCode === 'TWD' ? '<b>TWD</b>(<b>NTD</b>)' : '<b>HKD</b' + '顯示。</p>');
  return {
    options: {
      language: currencyCode === 'TWD' ? 'zh-TW' : 'zh-HK',
      expressCheckout: false,
      shipToCountries: currencyCode === 'TWD' ? ['TW'] : ['HK'],
      billToCountries: currencyCode === 'TWD' ? ['TW'] : ['HK'],
      style: {
        modal: {
          logo: 'https://images.ctfassets.net/hnoi7ctzfs57/6ijgGIdGdCyUnLUC3bIFyT/cdff6d6603c6707a75f9b0396b62fd73/Group.svg',
          themeColor: {
            primary: '#E60000',
            highlight: '#C30000',
            background: {
              header: '#fff',
              mainContent: '#fff',
              orderSummary: '#fff',
              footer: '#000',
            },
            text: {
              link: '#00a7e1',
              footerLink: '#fff',
              button: '#fff',
            },
          },

          borderRadius: '20px',
          fontFamily: 'Montserrat, sans-serif',
          fontVariant: 'normal',
          letterSpacing: '1px',
        },
        textField: {
          base: {
            color: '#000',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: '18px !important',
            fontVariant: 'normal',
            letterSpacing: '0.2px',
          },
          borderRadius: '8px',
        },
      },
      labelText: {
        country: {
          'zh-tw': '寄送地',
          'zh-hk': '寄送地',
          zh: '寄送地',
        },
      },
    },
    onInit: (checkoutSession: any, actions: any) => {
      actions.orderSummary.middle.addCustomMessage(text);
    },
  };
};

export const isDateLagerThanOneMonthFromNow = (date?: number): boolean => {
  const now = new Date();
  if (!date || isNaN(new Date(date).getTime())) {
    console.log('Invalid datetime string');
    return false; // or you can throw an error
  }

  const oneMonthAgo = Math.floor(
    new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).getTime()
  );
  return date > oneMonthAgo;
};
