export const NEWS_LISTING_SOURCE = {
  seaFacebook: 'SEA Facebook',
  byTipsNTricksCategory: 'By Tips & Tricks Category',
  latest6MonthSalesNEvent: 'Latest 6 Month - Sales & Event',
};

export const GAME_LISTING_SOURCE = {
  comingSoonGames: 'Coming Soon Games',
  gameListingPopular: 'Game Listing Popular',
  gameListingOurPick: 'Game Listing OurPick',
  gameListingParty: 'Game Listing Party',
  gamesListingTowGenre: 'Two Genres',
};

export const THEME = {
  DEFAULT: 'default',
};

export const GLOBAL_TYPE = {
  adsContainer: 'AdsContainer',
  policiesAndNotes: 'PoliciesAndNotes',
  subNavigationBar: 'SubNavigationBar',
  content404: 'Content404',
  contentMaintenance: 'ContentMaintenance',
  listPagePolicy: 'listPagePolicy',
  searchByCategory: 'SearchByCategory',
  searchByCategoryStore: 'SearchByCategoryStore',
  listTabsOnSearch: 'ListTabsOnSearch',
  listTabsOnSearchStore: 'ListTabsOnSearchStore',
  translation: 'Translation',
  listTrendingForSearch: 'ListTrendingForSearch',
  newsFilterColor: 'NewsFilterColor',
  newsFilter: 'NewsFilter',
  subscribe: 'Subscribe',
  country: 'Country',
  FilterPriceProducts: 'FilterPriceProducts',
  SortByProducts: 'SortByProducts',
  StoreTab: 'StoreTab',
  ImageLogin: 'ImageLogin',
  SEO: 'SEO',
};
