import axiosClient from './axios-client';
// Webhook url
const webhookUrl = process.env.NEXT_PUBLIC_WEBHOOK_URL;

const webhookApi = {
  /**
   * Convert list image to pdf file (base64 of pdf)
   * @param formData // files: []
   * @returns pdfBase64
   */
  convertPDF(formData: FormData) {
    return axiosClient({
      url: `${webhookUrl}/convert-pdf`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  },
};
export default webhookApi;
