import axiosClient from './axios-client';

const countryApi = {
  getIP(url: string) {
    return axiosClient({
      method: 'GET',
      url: url,
    });
  },
};

export default countryApi;
