import {Box} from '@mui/material';
import {useRouter} from 'next/router';
import React, {useEffect, useMemo, useState} from 'react';
import {handleSubScribeButton} from '~/utils/button-functions';
import dynamic from 'next/dynamic';
import {usePageSetting} from '~/providers/page-context.provider';
const SubscribePopup = dynamic(
  () => import('@wearesection/bandai-react-components/organisms/SubscribePopup'),
  {ssr: false}
);

const adapter = (
  data: any,
  handleSuccess: Function,
  locale: string,
  setReturnServer: Function,
  isOpenPopup: boolean,
  dataTranslation: any
) => {
  return {
    ...data,
    subscribeModal: {
      ...data.subscribeModal,
      contentText: {
        ...data.subscribeModal?.contentText,
        description:
          data?.subscribeModal?.contentText?.description?.replace(
            /{locale}/g,
            `/${locale?.toLocaleLowerCase()}`
          ) ?? '',
      },
      hrefNextPage: `/${locale}` + data?.subscribeModal?.hrefNextPage,
      onSubscribe: (e: string) => {
        handleSubScribeButton(e, locale)?.then(async result => {
          if (result.status === 200) {
            setReturnServer(0);
            handleSuccess();
          } else {
            if (
              result.status === 207 ||
              result.status === 206 ||
              result.status === 429 ||
              result.status === 409
            ) {
              setReturnServer(result.status);
            } else {
              setReturnServer(500);
            }
          }
        });
      },
      isOpen: isOpenPopup,
      locale: `${locale}`.toLowerCase(),
      contentCheckboxKoKr:
        `${locale}`.toLowerCase() === 'ko-kr'
          ? {
              termsConditions: dataTranslation?.['estore-subcribe-termsConditions'] || '',
              // '<a href="/ko-kr/terms-of-use" target="_blank">개인정보처리방침</a>과 <a href="/ko-kr/privacy-policy" target="_blank">이용 약관</a>에 동의하고 뉴스레터를 구독합니다.',
              privacyPolicy: dataTranslation?.['estore-subcribe-privacyPolicy'] || '',
              // '광고성 정보 수신에 동의합니다.',
            }
          : '',
    },
  };
};

const SubscribeOrganisms: React.FC = () => {
  const router = useRouter();
  const {locale} = router;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [stateProps, setStateProps] = useState<any>({});
  const [stateCount, setStateCount] = useState<number>(0);
  const [returnServer, setReturnServer] = useState<number>(0);
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);

  const handleClosePopup = () => {
    setReturnServer(0);
  };

  const {globalData} = usePageSetting();
  const dataTranslation = globalData?.dataTranslation?.[0] ?? {};

  useEffect(() => {
    setStateProps(globalData.dataSubscribe);
  }, [globalData.dataSubscribe]);
  const handleSuccess = () => {
    setStateCount(stateCount + 1);
    setStateProps({
      ...stateProps,
      subscribeModal: {
        ...stateProps.subscribeModal,
        stepForm: stateCount + 1,
      },
    });
  };

  useEffect(() => {
    setStateProps({
      ...stateProps,
      locale: router.locale,
    });

    if (typeof window !== 'undefined') {
      const asPath = router?.asPath || '';
      const hash = `${asPath}`.split('#')[1] || '';
      if (hash) {
        const hashSubscribe = hash.split('?')[0];
        if (hashSubscribe && hashSubscribe === 'subscribe-newsletter') {
          setIsOpenPopup(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.locale]);

  const PADDING_COMPONENT: any = {
    '/[[...slug]]': {
      paddingTop: {
        // xs: '72px',
        // md: '80px',
      },
    },
  };

  const SubscribeOrganismsProps = useMemo(
    () =>
      adapter(
        stateProps,
        handleSuccess,
        locale ?? 'en-us',
        setReturnServer,
        isOpenPopup,
        dataTranslation
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stateProps?.subscribeModal?.stepForm, isOpenPopup, locale, dataTranslation]
  );

  return (
    <Box sx={PADDING_COMPONENT[router.pathname] ?? {}} marginTop="auto">
      <SubscribePopup
        handleClosePopup={handleClosePopup}
        {...SubscribeOrganismsProps}
        returnServerCode={returnServer}
      />
    </Box>
  );
};

export default SubscribeOrganisms;
