/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '../../types';

import * as TypescriptOperationsGenerated from "graphql/typescript-operations.generated"
import * as Operations from 'graphql/document-nodes.generated';
import { NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router'
import { QueryHookOptions, useQuery } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type React from 'react';
import { getApolloClient , ApolloClientContext} from 'withApollo';
export async function getServerPageGetListblog
    (options: Omit<Apollo.QueryOptions<TypescriptOperationsGenerated.GetListblogQueryVariables>, 'query'>, ctx: ApolloClientContext ){
        const apolloClient = getApolloClient(ctx);
        
        const data = await apolloClient.query<TypescriptOperationsGenerated.GetListblogQuery>({ ...options, query: Operations.GetListblogDocument });
        
        const apolloState = apolloClient.cache.extract();

        return {
            props: {
                apolloState: apolloState,
                data: data?.data,
                error: data?.error ?? data?.errors ?? null,
            },
        };
      }
export const useGetListblog = (
  optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetListblogQuery, TypescriptOperationsGenerated.GetListblogQueryVariables>) => {
  const router = useRouter();
  const options = optionsFunc ? optionsFunc(router) : {};
  return useQuery(Operations.GetListblogDocument, options);
};
export type PageGetListblogComp = React.FC<{data?: TypescriptOperationsGenerated.GetListblogQuery, error?: Apollo.ApolloError}>;
export const withPageGetListblog = (optionsFunc?: (router: NextRouter)=> QueryHookOptions<TypescriptOperationsGenerated.GetListblogQuery, TypescriptOperationsGenerated.GetListblogQueryVariables>) => (WrappedComponent:PageGetListblogComp) : NextPage  => (props) => {
                const router = useRouter()
                const options = optionsFunc ? optionsFunc(router) : {};
                const {data, error } = useQuery(Operations.GetListblogDocument, options)    
                return <WrappedComponent {...props} data={data} error={error} /> ;
                   
            }; 
export const ssrGetListblog = {
      getServerPage: getServerPageGetListblog,
      withPage: withPageGetListblog,
      usePage: useGetListblog,
    }