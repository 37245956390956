import {ssrGetListNew} from '~/graphql/operations/new/get-list-new-query.generated';
import * as Types from '../../graphql/types';

async function getListNewByTag(
  locale?: string,
  tagName?: Array<string>,
  limit: Types.NewsCollection['limit'] = 10,
  calendarReel?: string,
  skip: Types.NewsCollection['skip'] = 0,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  game?: string,
  tab?: string,
  timeRanges?: {
    from: string;
    to: string;
  },
  typeFilter?: string
) {
  let newQuery: any = {};
  type IQuery = {
    customTags?: string;
    secondaryTags_contains_all?: string;
  };
  const tagNameFilter = tagName?.filter(n => n);

  if (tagNameFilter && game) {
    const arrayQuery: string[] = [];
    tagNameFilter.map(name => {
      const query: IQuery = {
        customTags: name,
      };
      arrayQuery.push(query as any);
    });
    tagNameFilter.map(name => {
      const query: IQuery = {
        secondaryTags_contains_all: name,
      };
      arrayQuery.push(query as any);
    });
    newQuery = {
      OR: arrayQuery,
      AND: [{game: {title: game}}],
    };
  }

  if (tagNameFilter && !game) {
    const arrayQuery: string[] = [];
    tagNameFilter.map(name => {
      const query: IQuery = {
        customTags: name,
      };
      arrayQuery.push(query as any);
    });
    tagNameFilter.map(name => {
      const query: IQuery = {
        secondaryTags_contains_all: name,
      };
      arrayQuery.push(query as any);
    });
    newQuery = {
      OR: arrayQuery,
    };
  }

  if (tab) {
    if (tab === 'Patch notes') {
      newQuery.AND.push({secondaryTags_contains_all: tab});
    }
  }

  // newQuery.title_exists = true;
  if (calendarReel) {
    newQuery.publishDate_gte = calendarReel;
  }

  if (typeFilter === 'update') {
    if (timeRanges) {
      newQuery = {
        OR: newQuery.OR,
        AND: [{game: {releaseDate_lt: timeRanges?.to}}],
      };
    }
  }

  if (typeFilter === 'new') {
    if (timeRanges) {
      newQuery = {
        OR: newQuery.OR,
        AND: [
          {game: {releaseDate_gte: timeRanges?.from}},
          {game: {releaseDate_lte: timeRanges?.to}},
        ],
      };
    }
  }

  if (typeFilter === 'coming') {
    if (timeRanges) {
      newQuery = {
        OR: newQuery.OR,
        AND: [{game: {releaseDate_gt: timeRanges?.from}}],
      };
    }
  }
  newQuery.listInListingPage = true;
  newQuery.title_exists = true;
  const {
    props: {data},
  } = await ssrGetListNew.getServerPage(
    {
      variables: {
        limit,
        skip,
        where: newQuery,
        locale,
      },
    },
    {}
  );

  return data?.newsCollection;
}

export default getListNewByTag;
