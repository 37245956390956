import {createSlice} from '@reduxjs/toolkit';
import LocalStorage, {TITLE_LOCAL_STORAGE} from '~/local-storage/local-storage';

type IOrder = {
  orderListCurrent: any[];
  orderListCompleted: any[];
};
const orderList = LocalStorage.getItem(TITLE_LOCAL_STORAGE.orderList);

const initialState: IOrder = {
  orderListCurrent: orderList?.orderListCurrent || [],
  orderListCompleted: orderList?.orderListCompleted || [],
};

export const orders = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    saveOrder: (state, action) => {
      state.orderListCurrent = action.payload.orderListCurrent || [];
      state.orderListCompleted = action.payload.orderListCompleted || [];
      LocalStorage.setItem(TITLE_LOCAL_STORAGE.orderList, {
        orderListCurrent: action.payload.orderListCurrent || [],
        orderListCompleted: action.payload.orderListCompleted || [],
      });
    },
    removeOrder: state => {
      state.orderListCurrent = [];
      state.orderListCompleted = [];
      LocalStorage.removeItem(TITLE_LOCAL_STORAGE.orderList);
    },
  },
});

export const {saveOrder, removeOrder} = orders.actions;

export default orders.reducer;
