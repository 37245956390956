import axios from 'axios';
const TIME_OUT = 30 * 1000;
// import {getCookie} from 'cookies-next';

const axiosClient = axios.create({
  baseURL: '/api',
  timeout: TIME_OUT,
  // headers: {
  //   'x-access-token':
  //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTYsImVtYWlsIjoiVGVzdGluZ0BnbWFpbC5jb20iLCJpYXQiOjE2NzIyMTgyMzMsImV4cCI6MTY3MjMwNDYzM30.BNizVJCl1ojuVv_hAZ6nB6LMzRZ5U65FszrF3l-cxl4',
  // },
});

// Add a response interceptor
axiosClient.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return {
      status: response.status,
      data: response.data,
    };
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosClient;
