import {ssrGetGlobalByType} from '~/graphql/operations/global/get-global-by-type.generated';

export async function getGloablByType(locale: string, type: string) {
  const {
    props: {data},
  } = await ssrGetGlobalByType.getServerPage({variables: {type: type}}, {});

  return (
    data?.globalCollection?.items?.[0]?.configuration?.[locale] ??
    data?.globalCollection?.items?.[0]?.configuration
  );
}
