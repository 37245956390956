/* eslint-disable @typescript-eslint/no-explicit-any */
//@ts-nocheck
import {
  handleTranslateBlogTag,
  handleConvertToLocaleContentful,
  handleTranslateNews,
  handleReturnDateAfterSubtraction,
  handleCovertLocale,
} from './index';
import {IContactUs} from '@wearesection/bandai-react-components/organisms/ContactUs';
import {ButtonHandler} from '@wearesection/bandai-react-components/atoms/Button';
import getListNew from '~/pages/news/get-news-by-tag';
import getListBlog from '~/pages/blog/get-blog-by-tag';
import {PLACEHOLDER_IMG} from '~/constants';
import axios from 'axios';
// import {usePageSetting} from '~/providers/page-context.provider';
import {handleReturnDateForLocal} from '~/components/wrapper/organisms/scrolling-thumbnail-card/scrolling-thumbnail-card';
import {getGloablByType} from './global-operations';
import {GLOBAL_TYPE} from '~/constants/content-map-type';
import {usePageSetting} from '~/providers/page-context.provider';
import userApi from '~/pages/api/services/user-api';
import webhookApi from '~/pages/api/services/webhook-api';

export type ContactUsHandler = ButtonHandler<IContactUs>['handler'];

export const handleSubmitContactUs: ContactUsHandler = async (_, props, data: any) => {
  if (data) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value as string | Blob);
    }

    const language = handleCovertLocale(data?.locale || 'en-us');
    formData.append('language', language);

    if (data?.files?.length > 0) {
      const formData1 = new FormData();
      data.files.forEach((item: any) => {
        formData1.append('files', item);
      });
      // Call webhook API to convert images to pdf-base64
      const response = await webhookApi.convertPDF(formData1);
      formData.append('base64PDFs', response.data.pdfBase64);
    }
    const response = await userApi.contactUs(formData);
    return response;
  }
};

export const handleGetDataNews = async (
  locale: string,
  query: string[],
  limit: number,
  skip: number,
  game: string,
  tagColor: any,
  dataTranslation: any,
  tab?: string
) => {
  const newLocal = locale.toLowerCase();
  let filterTab = '';
  // if (newLocal === 'hk-sc') {
  //   newLocal = 'hk-tc';
  // }
  // if (newLocal === 'tw-sc') {
  //   newLocal = 'tw-tc';
  // }

  const typeOfGameUpdate = ['Game update', '게임 업데이트', '游戏更新', '遊戲更新'];
  const typeOfNewGame = ['New game', '신작 게임', '最新游戏', '最新遊戲'];
  const typeOfUpcomingGame = ['Upcoming game', '발매 예정 게임', '即将上市', '即將發售'];
  let promises: any = [];
  const today = new Date();

  if (tab) {
    filterTab = tab === '1' ? '' : 'Patch notes';
  }

  if (typeOfGameUpdate.includes(query[0])) {
    const timeRanges = {
      from: today.toISOString(),
      to: handleReturnDateAfterSubtraction(today, 3).toISOString(),
    };

    promises = [
      getListNew(
        handleConvertToLocaleContentful(newLocal as string),
        query ?? [],
        limit,
        '',
        skip,
        game,
        filterTab,
        timeRanges,
        'update'
      ),
    ];
  } else if (typeOfNewGame.includes(query[0])) {
    const timeRanges = {
      from: handleReturnDateAfterSubtraction(today, 3).toISOString(),
      to: new Date().toISOString(),
    };

    promises = [
      getListNew(
        handleConvertToLocaleContentful(newLocal as string),
        query ?? [],
        limit,
        '',
        skip,
        game,
        filterTab,
        timeRanges,
        'new'
      ),
    ];
  } else if (typeOfUpcomingGame.includes(query[0])) {
    const timeRanges = {
      from: new Date().toISOString(),
      to: '',
    };

    promises = [
      getListNew(
        handleConvertToLocaleContentful(newLocal as string),
        query ?? [],
        limit,
        '',
        skip,
        game,
        filterTab,
        timeRanges,
        'coming'
      ),
    ];
  } else {
    promises = [
      getListNew(
        handleConvertToLocaleContentful(newLocal as string),
        query ?? [],
        limit,
        '',
        skip,
        game,
        filterTab
      ),
    ];
  }

  const [listNew] = await Promise.all(promises);
  if (listNew && listNew?.items.length > 0) {
    const items = listNew?.items.map((item: any) => {
      return {
        title: item?.title ?? '',
        linkHref: `/${locale}/news/${item?.slug}`,
        mainImage: item?.mainImage?.media?.url ?? PLACEHOLDER_IMG,
        mainImageNext: '',
        tags: [
          {
            title: handleTranslateNews(item?.customTags, dataTranslation, item, locale),
            color: tagColor?.find(
              (e: any) =>
                e?.name?.toLocaleLowerCase() ===
                ((
                  handleTranslateNews(item?.customTags, dataTranslation, item, locale) as string
                )?.toLocaleLowerCase() as string)
            )?.backgroundColor,
          },
        ],
        date: handleReturnDateForLocal(locale || 'en-sg', `${item?.publishDate}`.substring(0, 10)),
        hrefTag: `/${locale}/news`,
      };
    });
    return {
      items: items,
      limit: listNew.limit,
      total: listNew.total,
      skip: listNew.skip,
    };
  } else {
    return [];
  }
};

export const handleSubScribeButton = (data: string, locale: string) => {
  if (data) {
    const formData = new FormData();
    formData.append('locale', locale);
    formData.append('email', data);

    return fetch('/api/subscribe-email-updates', {
      headers: {},
      body: formData,
      method: 'POST',
    });
  }
};

export const handleGetDataBlogOnNews = async (
  locale: string,
  query: string[],
  limit: number,
  skip: number
) => {
  const reamapquery: string[] = query ?? [];
  const promises: [
    ReturnType<typeof getListBlog>,
    ReturnType<typeof getGloablByType>,
    ReturnType<typeof getGloablByType>
  ] = [
    getListBlog(locale, reamapquery ?? [], limit, '', skip),
    getGloablByType(locale, GLOBAL_TYPE.newsFilterColor),
    getGloablByType(locale, GLOBAL_TYPE.translation),
  ];
  const [listNew, listTagsColor, dataTranslation] = await Promise.all(promises);

  if (listNew && listNew?.items.length > 0) {
    const items = listNew?.items.map(item => ({
      title: item?.title ?? '',
      linkHref: `/${locale}/blog/` + item?.slug ?? '/',
      hrefTag: `/${locale}/blog`,
      mainImage: (item?.gameTitle?.gamesFilterThumbnailImage as any)?.media?.url ?? PLACEHOLDER_IMG,
      mainImageNext: '',
      description: item?.shortDescription ?? '',
      date:
        handleReturnDateForLocal(locale || 'en-sg', `${item?.publishDate}`.substring(0, 10)) ?? '',
      tags: [
        {
          title: handleTranslateBlogTag(item?.primaryTag as string, dataTranslation),
          color: listTagsColor?.find(
            (e: any) =>
              e.name ===
              handleTranslateBlogTag(
                item?.primaryTag?.toLocaleLowerCase() as string,
                dataTranslation
              )
          )?.backgroundColor,
        },
      ],
    }));
    return {
      items: items,
      limit: listNew.limit,
      total: listNew.total,
      skip: listNew.skip,
    };
  } else {
    return [];
  }
};

export const handleFormatDataMastheadPrices = (
  bcProduct: any,
  dataTranslation: any
): {title: string; value: string} => {
  const result = {title: 'From', value: '0.00'};
  if (!bcProduct) {
    return result;
  }
  const price = bcProduct?.site?.product?.prices?.price || {};
  return {
    title: dataTranslation['product-from'],
    value: `${dataTranslation['product-currency']} ${
      price?.value ? `${Number.parseFloat(price?.value).toFixed(2)}` : '0.00'
    }`,
  };
};
