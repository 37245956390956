import axios from 'axios';
import axiosClient from './axios-client';
const url = '/customer/profile';

let cancelToken: any = undefined;

const userApi = {
  getProfile(token: string) {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('cancelToken');
    }
    cancelToken = axios.CancelToken.source();
    return axiosClient({
      method: 'GET',
      url,
      cancelToken: cancelToken.token,
      headers: {
        'x-access-token': token ?? '',
      },
    });
  },
  updateProfile(data: any) {
    return axiosClient({
      url,
      method: 'PUT',
      data,
    });
  },
  resetPassWord(data: any, token: string) {
    return axiosClient({
      url: '/customer/reset-password',
      method: 'PUT',
      data: {
        ...data,
        token,
      },
    });
  },
  updatePassWord(data: any) {
    return axiosClient({
      url: '/customer/password',
      method: 'PUT',
      data,
    });
  },
  validatePassWord(data: any) {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel('cancelToken');
    }
    cancelToken = axios.CancelToken.source();
    return axiosClient({
      url: '/customer/validate-password',
      method: 'POST',
      data,
      cancelToken: cancelToken.token,
    });
  },
  subcribeEmail(data: any) {
    return axiosClient({
      url: '/subscribe-newsletter',
      method: 'POST',
      data,
    });
  },
  contactUs(formData: any) {
    return axiosClient({
      url: '/contact-us',
      method: 'POST',
      data: formData,
    });
  },
};

export default userApi;
