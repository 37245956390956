import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {createWrapper} from 'next-redux-wrapper';
import user from './userSlice';
import orders from './orderSlice';
import cart from './cartSlice';
import productList from './productListSlice';
import productListRecently from './productListRecentlySlice';
import merchandise from './merchandiseSlice';
import dealEventList from './dealEventSlice';

const combineReducer = combineReducers({
  user,
  orders,
  cart,
  productList,
  productListRecently,
  merchandise,
  dealEventList,
});

export const makeStore = () => configureStore({reducer: combineReducer});

export const wrapper = createWrapper(makeStore);
