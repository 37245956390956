import {createSlice} from '@reduxjs/toolkit';
import {ListingType} from '@wearesection/bandai-react-components/molecules/FilterSelectionListing';
import {IProductItemMerchandise} from '@wearesection/bandai-react-components/organisms/EStoreMerchandiseListing/ProductItem';
import {TypeDataFilter} from '@wearesection/bandai-react-components/organisms/ScrollingFilterListing';

type IDealEvent = {
  dataFilter: TypeDataFilter[];
  filterPrice: ListingType[];
  sortBy: ListingType[];
  tabSelect: number;
  tabListFilter: any[];
  sortItem: string;
  filterItem: any;
  productData: IProductItemMerchandise[];
  endCursor: any;
  endFeatured: boolean;
};

const initialState: IDealEvent = {
  dataFilter: [],
  sortBy: [],
  filterPrice: [],
  tabSelect: 1,
  tabListFilter: [],
  sortItem: 'FEATURED',
  filterItem: {},
  productData: [],
  endCursor: '',
  endFeatured: false,
};

export const dealEvent = createSlice({
  name: 'dealEventList',
  initialState,
  reducers: {
    setDataFilter: (state, action) => {
      state.dataFilter = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setFilterPrice: (state, action) => {
      state.filterPrice = action.payload;
    },
    setTabSelect: (state, action) => {
      state.tabSelect = action.payload;
    },
    setSortItem: (state, action) => {
      state.sortItem = action.payload;
    },
    setFilterItem: (state, action) => {
      state.filterItem = action.payload;
    },
    addDealEventData: (state, action) => {
      state.productData = [...state.productData, ...action.payload];
    },
    setDealEventData: (state, action) => {
      state.productData = action.payload;
    },
    setEndCursor: (state, action) => {
      state.endCursor = action.payload;
    },
    setEndFeatured: (state, action) => {
      state.endFeatured = action.payload;
    },
    setTabListFilter: (state, action) => {
      state.tabListFilter = action.payload;
    },
  },
});

export const {
  setFilterPrice,
  setSortBy,
  setDataFilter,
  setTabSelect,
  setSortItem,
  setFilterItem,
  addDealEventData,
  setDealEventData,
  setEndCursor,
  setEndFeatured,
  setTabListFilter,
} = dealEvent.actions;

export default dealEvent.reducer;
