import {Box} from '@mui/material';
import React from 'react';
import RenderNextImage from '../../atoms/render-next-image/render-next-image';
import Typography from '@wearesection/bandai-react-components/atoms/Typography';
import Button from '@wearesection/bandai-react-components/atoms/Button';
import {IMG_NO_PATCH_NOTES} from '~/constants';

export default function MaintenancePage(props: any) {
  const data = props?.data || {};
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        minHeight: '50vh',
        background: 'red',
        backgroundColor: '#fff',
        padding: '0px 24px',

        img: {
          width: {
            xs: '100px',
            md: '200px',
          },
          height: {
            xs: '100px',
            md: '200px',
          },
        },
      }}
    >
      <RenderNextImage src={data?.image || IMG_NO_PATCH_NOTES} alt="logo" />
      <Typography
        variant="p2"
        textTransform={undefined}
        sx={{
          margin: '24px 0px',
          textAlign: 'center',
        }}
      >
        {data?.content_text || ''}
      </Typography>
      <Button externalLink={`/${props?.locale || 'sea'}`} variant="primaryDark">
        {data?.titleHome || 'Home page'}
      </Button>
    </Box>
  );
}
