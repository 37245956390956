/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {handleConvertToLocaleContentful} from '~/utils';
import {getGloablByType} from '~/utils/global-operations';
import {useRouter} from 'next/router';
import {LIST_ID_GAME_POPULAR} from '~/constants';
import {GLOBAL_TYPE} from '~/constants/content-map-type';
import {getLitTypePopular} from './get-list-type-popular';
import {getListGameByQueryValue} from '~/pages/games/get-list-game-by-query-value';
import {TITLE_LOCAL_STORAGE} from '~/local-storage';
import LocalStorage from '~/local-storage/local-storage';

type PageSettingType = {
  setTheme: Function;
  theme: string;
  themeOption: any;
  setThemeOption: Function;
  openSearchPopup: boolean;
  setOpenSearchPopup: Function;
  globalData: GlobalDataType;
  setGlobalData: Function;
  statusShowBannerUpdateLocale: boolean;
  setStatusShowBannerUpdateLocale: Function;
  setDataSlug: Function;
  dataSlug: any;
};

type GlobalDataType = {
  listTagsColor: any;
  dataTranslation: any[];
  dataSubscribe: any;
  listCountry: any[];
  listPhoneNumberCode: any[];
};

const PageSettingContext = React.createContext<PageSettingType>({
  theme: 'default',
  setTheme: () => null,
  themeOption: {},
  setThemeOption: () => null,
  openSearchPopup: false,
  setOpenSearchPopup: () => null,
  globalData: {
    listTagsColor: undefined,
    dataTranslation: [{}],
    dataSubscribe: {},
    listCountry: [],
    listPhoneNumberCode: [],
  },
  setGlobalData: () => null,
  statusShowBannerUpdateLocale: false,
  setStatusShowBannerUpdateLocale: () => null,
  setDataSlug: () => null,
  dataSlug: {},
});
export const usePageSetting = () => useContext(PageSettingContext);

export const PageSettingProvider: React.FC = ({children}) => {
  const [theme, setTheme] = useState('default');
  const [themeOption, setThemeOption] = useState('default');
  const [openSearchPopup, setOpenSearchPopup] = useState(false);
  const [dataSlug, setDataSlug] = useState({});
  const [statusShowBannerUpdateLocale, setStatusShowBannerUpdateLocale] = useState(false);

  const dataGlobal =
    typeof window !== 'undefined'
      ? JSON.parse(
          localStorage?.getItem(TITLE_LOCAL_STORAGE.dataGlobal) ||
            '{"listTagsColor":null,"dataTranslation":[],"dataPopularTrending":null,"dataSubscribe":{},"listCountry":[],"listPhoneNumberCode":[]}'
        )
      : {};

  const [globalData, setGlobalData] = useState({
    listTagsColor: dataGlobal?.listTagsColor || null,
    dataTranslation: dataGlobal?.dataTranslation || [{}],
    dataPopularTrending: dataGlobal?.dataPopularTrending || null,
    dataSubscribe: dataGlobal?.dataSubscribe || {},
    listCountry: dataGlobal?.listCountry || [],
    listPhoneNumberCode: dataGlobal?.listPhoneNumberCode || [],
  });
  const {locale = ''} = useRouter();

  /**
   * Get common data
   */
  useEffect(() => {
    const handleGetCommonData = async () => {
      const promises: [
        ReturnType<typeof getGloablByType>,
        ReturnType<typeof getGloablByType>,
        ReturnType<typeof getGloablByType>,
        ReturnType<typeof getLitTypePopular>,
        ReturnType<typeof getGloablByType>,
        ReturnType<typeof getGloablByType>
      ] = [
        getGloablByType(
          handleConvertToLocaleContentful(locale as string),
          GLOBAL_TYPE.newsFilterColor
        ),
        getGloablByType(handleConvertToLocaleContentful('sea'), GLOBAL_TYPE.translation),
        getGloablByType(handleConvertToLocaleContentful(locale as string), GLOBAL_TYPE.translation),
        getLitTypePopular(
          LIST_ID_GAME_POPULAR[handleConvertToLocaleContentful(locale as string)] ??
            LIST_ID_GAME_POPULAR['en-sg'],
          handleConvertToLocaleContentful(locale as string)
        ),
        getGloablByType(handleConvertToLocaleContentful(locale as string), GLOBAL_TYPE.subscribe),
        getGloablByType(handleConvertToLocaleContentful(locale as string), GLOBAL_TYPE.country),
      ];

      const [
        listTagsColor,
        dataTranslationEN,
        dataTranslation,
        dataPopularTrending,
        dataSubscribe,
        country,
      ] = await Promise.all(promises);

      const listGamePopular: any =
        (await getListGameByQueryValue(
          handleConvertToLocaleContentful(locale as string),
          dataPopularTrending
        )) ?? [];

      setGlobalData({
        listTagsColor: listTagsColor ?? [],
        dataTranslation: [dataTranslation, dataTranslationEN] ?? [],
        dataPopularTrending: (listGamePopular as any) ?? [],
        dataSubscribe: dataSubscribe,
        listCountry: country?.country || [],
        listPhoneNumberCode: country?.phone_number_code || [],
      });
      return {
        listTagsColor: listTagsColor,
        dataTranslation: dataTranslation,
        dataPopular: dataPopularTrending,
        dataSubscribe: dataSubscribe,
      };
    };

    handleGetCommonData();
  }, []);

  useEffect(() => {
    LocalStorage.setItem(TITLE_LOCAL_STORAGE.dataGlobal, globalData);
  }, [globalData]);

  return (
    <PageSettingContext.Provider
      value={{
        theme,
        setTheme,
        themeOption,
        setThemeOption,
        openSearchPopup,
        setOpenSearchPopup,
        globalData,
        setGlobalData,
        statusShowBannerUpdateLocale,
        setStatusShowBannerUpdateLocale,
        dataSlug,
        setDataSlug,
      }}
    >
      {children}
    </PageSettingContext.Provider>
  );
};

PageSettingProvider.propTypes = {};

PageSettingProvider.defaultProps = {};
